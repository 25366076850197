<template>
  <master-layout :pageTitle="'Riepilogo ordini'">
    <div style="padding-bottom: 100px">
      <ion-grid>
        <ion-row class="table-header">
          <ion-col>
            <ion-row>
              <ion-col> Seleziona il cliente </ion-col>
            </ion-row>
            <ion-row>
              <ion-col
                ><ion-select
                  v-model="clienteSelezionato"
                  placeholder="Seleziona cliente..."
                >
                  <ion-select-option
                    v-for="azienda of aziende"
                    :key="azienda.id"
                  >
                    {{ azienda.name }} {{ azienda.surname }}
                  </ion-select-option>
                </ion-select></ion-col
              >
            </ion-row>
            <ion-row>
              <ion-col size="3">Data</ion-col>
              <ion-col size="4">Cliente</ion-col>
              <ion-col size="3">Pezzi</ion-col>
              <ion-col size="2">Importo </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row
          @dismissed="console"
          class="riga-prodotto"
          v-for="item of items"
          :key="item.code"
        >
          <ion-col size="3">
            {{ item.date }}
          </ion-col>
          <ion-col size="4">
            {{ item.cliente }}
          </ion-col>
          <ion-col size="3">
            {{ item.promo }}
          </ion-col>
          <ion-col size="2"> € {{ item.price.toFixed(2) }} </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </master-layout>
</template>

<script>
import { IonCol, IonGrid, IonRow, modalController } from "@ionic/vue";
import YoubeSingleItem from "./YoubeSingleItem.vue";
import YoubeDettaglioOrdine from "./YoubeDettaglioOrdine.vue";
export default {
  name: "catalogo",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    YoubeSingleItem,
    YoubeDettaglioOrdine,
  },
  data() {
    return {
      clienteSelezionato: {},
      items: [
        {
          code: "7890",
          date: "05/12/2022",
          cliente: "Mario Pagano",
          name: "Via Lactea",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/09/detersione-smart__gentle-cleasing-milk-prod-960x960.jpg",
          description:
            "Primario e primordiale il latte detergente di yoUBe attraversa il mito e la leggenda, per trasportarti dritto al centro di un nutrimento elementare. Una delicatezza galattica per una pelle divina.",
          format: "200 ml",
          price: 19.0,
          promo: 5,
          number: 0,
        },
        {
          code: "3486",
          date: "12/12/2022",
          cliente: "Mario Pagano",
          name: "Gioia Infinita",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/detersione-smart__glow-tonic-prod-960x960.jpg",
          description:
            "Un flusso di radioso entusiasmo inonda la voglia della tua pelle, fremente di detersione giooiosa, pronta ad accogliere tutta l’energia di un nuovo trattamento.",
          format: "200 ml",
          price: 15.0,
          promo: 4,
          number: 0,
        },
        {
          code: "9234",
          date: "26/12/2022",
          cliente: "Mario Pagano",
          name: "Vita Forte",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/09/detersione-smart__micellar-water-prod-960x960.jpg",
          description:
            "Forte come la vita che ti scorre incontro, l’acqua micellare di yoUBe riconduce la tua epidermide a una condizione originale. Intensamente gentile, rimuove trucco e impurità, idrata e protegge: 3 volte in 1.",
          format: "200",
          price: 15.0,
          promo: 3,
          number: 0,
        },
        {
          code: "1122",
          date: "28/12/2022",
          cliente: "Alice Rizzo",
          name: "A+MORE",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/detersione-smart__purifyng-face-scrub-prod-960x960.jpg",
          description:
            "Volersi + bene è una bellezza, un atto che libera ed emoziona ogni cosa. A levare in crescendo – e lasciare il superfluo – lo scrub viso di yoUBe t’innamora di te, purificando la tua pelle con il giusto sentimento.",
          format: "150 ml",
          price: 20.0,
          promo: 6,
          number: 0,
        },
      ],
      aziende: [
        {
          id: 1,
          name: "Mario",
          surname: "Pagano",
          pIva: 83094275483,
          telefono: "389 45611311",
        },
        {
          id: 2,
          name: "Giorgia",
          surname: "De Luca",
          pIva: 30489263483,
          telefono: "333 38381625",
        },
        {
          id: 3,
          name: "Alice",
          surname: "Rizzo",
          pIva: 12345678912,
          telefono: "334 23877723",
        },
        {
          id: 4,
          name: "Lorenzo",
          surname: "Barbieri",
          pIva: 21987654321,
          telefono: "324 34439284",
        },
        {
          id: 5,
          name: "Andrea",
          surname: "Villa",
          pIva: 12098744738,
          telefono: "368 34923900",
        },
      ],
    };
  },
  methods: {
    console() {
      console.log("ciao mamma");
    },
    async openModalDettaglioItem(item) {
      const modal = await modalController.create({
        component: YoubeSingleItem,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
        },
      });
      return modal.present();
    },
    async openModalCarrello(item, counter) {
      let cliente = this.clienteSelezionato;
      const modal = await modalController.create({
        component: YoubeDettaglioOrdine,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
          counter: counter,
          cliente: cliente,
        },
      });
      return modal.present();
    },
  },
};
</script>

<style scoped>
ion-grid {
  font-size: 0.8rem;
  /* font-weight: bolder; */
  color: grey;
}

img {
  border-radius: 4px;
}

.titolo {
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
}

.table-header ion-col {
  font-weight: bold;
}

.riga-prodotto ion-col {
  display: grid;
  place-items: start;
}
</style>